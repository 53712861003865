"use client";

import { useContext } from "react";

import { createContext } from "@skydio/rivet-ui";

import type { ReactNode } from "react";

// NOTE(sam): I'm not sure why but the useContext function returned by createContext isn't working
// correctly here - just going to use the one from react for now
const [Provider, , commitShaContext] = createContext<string>({
  name: "CommitShaContext",
});

export function CommitShaProvider({
  commitSha,
  children,
}: {
  commitSha: string;
  children: ReactNode;
}) {
  return <Provider value={commitSha}>{children}</Provider>;
}

export const useCommitSha = () => useContext(commitShaContext);
